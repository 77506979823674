import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import ContextComponent from './components/ContextComponent'
import ScrollToTop from './components/common/ScrollToTop'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google'
import CookieConsent from "react-cookie-consent";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <HelmetProvider prioritizeSeoTags>
    {/* <GoogleReCaptchaProvider reCaptchaKey={}> */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ContextComponent>
          <BrowserRouter>
            <ScrollToTop />
            <PayPalScriptProvider options={initialOptions}>
              <App />
            </PayPalScriptProvider>
          </BrowserRouter>
          <CookieConsent
            enableDeclineButton
            cookieName="europcharged-cookie-consent"
            buttonText="Accept All Cookies"
            declineButtonText="Continue without accepting"
            declineButtonClasses="px-4 bg-transparent text-decoration-underline text-white m-2"
            buttonClasses="px-4 rounded m-2"
            buttonStyle={{
              backgroundColor: '#0097b2',
              borderColor: '#0097b2',
              color: '#000'
            }}
            contentClasses="flex-grow-1"
            buttonWrapperClasses="flex-grow-1 d-flex flex-wrap justify-content-center"
            containerClasses="cookie-consent-parent col-lg-12"
            style={{ background: "#333333", borderColor: "#0097b2", maxWidth: "650px", right: 0, margin: "0 auto" }}
            expires={150}
          >
            By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
          </CookieConsent>
        </ContextComponent>
      </GoogleOAuthProvider>
    {/* </GoogleReCaptchaProvider> */}

  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
