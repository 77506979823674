import axios from 'axios'

export const callGet = (url, token, params = {}, byPassAuthorization = '', responseType = '') => {
  const headers = {}
  const responseTypes = {}

  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  if (byPassAuthorization) {
    headers.byPassAuthorization = `${byPassAuthorization}`
  }
  if (responseType) {
    responseTypes.responseType = responseType
  }
  return axios.get(`${process.env.REACT_APP_LOCAL_API_URL}${url}`, {
    headers,
    params,
    responseType
  })
}

export const callPost = (url, payload, token = '', byPassAuthorization = '') => {
  const headers = {}

  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  if (byPassAuthorization) {
    headers.byPassAuthorization = `${byPassAuthorization}`
  }
  return axios.post(`${process.env.REACT_APP_LOCAL_API_URL}${url}`, payload, {
    headers,
  })
}