import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import 'jquery-ui/dist/themes/base/jquery-ui.min.css'
import './assets/css/bootsnav.css'
import './assets/css/style.css'
import './assets/css/bootstrap.min.css'
import './assets/css/animate.css'
import './assets/css/flaticon.css'
import './assets/css/font-awesome.min.css'
import './assets/css/linearicons.css'
import './assets/css/responsive.css'
import './assets/js/jquery.js'
import './assets/js/bootstrap.min.js'
import './assets/js/feather.min.js'
import LocationsKml from './components/locations/locations.kml'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="pt-3 text-center">
          loading
        </div>
      }
    >
      <Routes>
        <Route path="*" name="HomePage" element={<DefaultLayout />} />
        <Route path="/locations.kml" name="HomePage" element={<LocationsKml />} />
      </Routes>
    </Suspense>
  )
}

export default App;
