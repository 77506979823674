import { useCallback, useEffect, useState } from 'react';
import { getCartCount } from 'src/services/actions/cart';
import Context from './CreateContext';

function ContextComponent({ children }) {
    const [cartCount, setCartCount] = useState(0);
    const [sitemapPost, setSitemapPost] = useState({});
    const [coords, setCoords] = useState({})

    const getCartCountData = useCallback(async (userId) => {
        try {
            const countCartData = await getCartCount({ userId });
            setCartCount(countCartData?.data?.totalCartProduct);
        } catch (err) {
            setCartCount(0)
        }
    }, [])

    useEffect(() => {
        const userLocalStorageData = localStorage?.getItem('userData') ? JSON.parse(localStorage?.getItem('userData')) : null
        if (userLocalStorageData) {
            var userId = userLocalStorageData?.id;
            getCartCountData(userId);
        }
    }, [getCartCountData])

    const onCartUpdate = async () => {
    
        const userLocalStorageData = localStorage?.getItem('userData') ? JSON.parse(localStorage?.getItem('userData')) : null
        var userId = userLocalStorageData?.id;
        if (userId) {
            getCartCountData(userId);
        } else {
            const cartData = JSON.parse(localStorage.getItem("cart")) || [];
            const totalQuantity = cartData?.reduce((total, item) => total + item.quantity, 0);
            setCartCount(cartData ? totalQuantity : 0)
        }
    }


    const onGetLocation = async () => {
        getLocation()
    }

    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        var latitude = position.coords.latitude
        var longitude = position.coords.longitude
        setCoords({
            latitude: latitude || '',
            longitude: longitude || ''
        })
    }

    return (
        <Context.Provider value={{ cartValue: cartCount, setCartValue: onCartUpdate, coords, getLocationCoords: onGetLocation ,setSitemapPost,sitemapPost}}>
            {children}
        </Context.Provider>
    );
}

export default ContextComponent