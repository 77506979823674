import React from 'react'

function LocationsKml() {
  return (
    <div>
      <div className='bg-primary w-100 text-white p-3'>
        <h1>KML File</h1>
        <p>This KML File is generated by <a href="https://rankmath.com/" className='text-white fs-6 text-decoration-none' target="_blank">Rank Math WordPress SEO Plugin</a>. It is used to provide location information to Google.</p>
        <p><a href="https://developers.google.com/kml/documentation/" className='text-white fs-6 text-decoration-none' target="_blank">KML File</a> Learn more about ..</p>

      </div>
      <div className='container mt-5'>
        <p className='text-dark'>This KML file contains 1 Locations.</p>
        <a href="/local-sitemap.xml" className='text-info fs-6 text-decoration-none'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
        </svg> sitemap index</a>
      
        <div className="table-responsive tableheight">
          <table className="table  align-middle  rounded border border-1">
            <thead className="text-nowrap position-sticky table-primary text-white" >
              <tr className='bg-primary' >
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Phone number</th>
                <th scope="col">Latitude</th>
                <th scope="col">Longitude</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              <tr>
                <th scope="row"><a href='/' target='_blank' className='fs-6 text-decoration-none text-info'> Eurocharged Performance </a></th>
                <td>24285 Katy Freeway, Suite 300, Katy, TX, 77494, USA</td>
                <td>+1-713-462-1600</td>
                <td></td>
                <td></td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default LocationsKml;
